import React, { useState } from "react";
import { IMAGES, SVGICON } from "../Dashboard/Content";
import circle from "./../../../images/circle.svg";
import { Row, Col, Card, Button, ButtonGroup } from "react-bootstrap";
import quotes from "./../../../images/quotes.svg";
import fetchData from "../../../axios";
import swal from "sweetalert";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const cardBlog = [
  { image: IMAGES.avatarpng1, title: "Samantha W." },
  { image: IMAGES.avatarpng2, title: "Karen Hope." },
  { image: IMAGES.avatarpng3, title: "Tony Soap" },
];

const tabledata = [
  {
    image: IMAGES.food3,
    title: "Beef Steak with Fried Potato",
    subtitle: "Snack",
    rating: "5.0",
    sales: "1,400",
    intrest: "17%",
  },
  {
    image: IMAGES.food5,
    title: "Pancake with Honey",
    subtitle: "Snack",
    rating: "4.8",
    sales: "1,456",
    intrest: "15%",
  },
];

const EditCourse = () => {
  const [course, setCourse] = useState({
    name: "",
    description: "",
    category: "",
    price: "",
    video: "",
    intro_video: "",
    thumbnail: "",
    resource: "",
    ppt: "",
    assessment: "",
    certificate: "",
    objective_define: "",
    What_you_will_learn: "",
    aims: [],
    who_should_attend: [],
    objectives_point: [],
    what_you_will_learn_point: [],
    RRP: "",
    course_type: "",
    duration: "",
    course_level: "",
    certificate_line: "",
    course_code: "",
  });

  const [aims, setAims] = useState("");
  const [who_should_attend, setWhoShouldSttend] = useState("");
  const [objectives_point, setObjectivesPoint] = useState("");
  const [what_you_will_learn, setWhatYouWillLearn] = useState("");
  const [what_you_will_learn_point, setWhatYouWillLearnPoint] = useState("");
  

  const { state } = useLocation();

  const [courseInfo, setCourseInfo] = useState({
    aims: "",
    who_should_attend: "",
    objectives_point: "",
    what_you_will_learn_point: "",
  });
  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPpt, setLoadingPpt] = useState(false);
  const [loadingRes, setLoadingRes] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [loadingThumb, setLoadingThumb] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [resource, setResource] = useState(null);
  const [ppt, setPpt] = useState(null);
  const navigate = useNavigate();

  const makeRequest = fetchData();

  function handlePointsChange(e) {
    setCourseInfo((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }
  function handleChange(e) {
    if (e.target.id) {
      validateFormFields(e.target.id);
    }
    setCourse((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }

  // Validate Form Fields - Start
  const validateFormFields = (callParam = "") => {
    let applyError = false;

    // Course Name Start
    if (callParam == "submit" || callParam == "name") {
      let name = document.getElementById("name");
      let name_msg = document.getElementById("name_msg");
      if (!name.value || name.value.trim() == "") {
        name.classList.add("border-danger");
        name_msg.innerText = "Please enter the course name!";
        if (applyError == false) {
          name.focus();
        }
        applyError = true;
      } else {
        name.classList.remove("border-danger");
        name_msg.innerText = "";
        applyError = false;
      }
    }
    // Course Name End

    // Course Category - Start
    if (callParam == "submit" || callParam == "category") {
      let category = document.getElementById("category");
      let category_msg = document.getElementById("category_msg");
      if (!category.value || category.value.trim() == "") {
        category.classList.add("border-danger");
        category_msg.innerText = "Please select the course category!";
        if (applyError == false) {
          category.focus();
        }
        applyError = true;
      } else {
        category.classList.remove("border-danger");
        category_msg.innerText = "";
        applyError = false;
      }
    }
    // Course Category - End

    // Selling Price - Start
    if (callParam == "submit" || callParam == "price") {
      let price = document.getElementById("price");
      let price_msg = document.getElementById("price_msg");
      if (!price.value || price.value.trim() == "") {
        price.classList.add("border-danger");
        price_msg.innerText = "Please enter the course selling price!";
        if (applyError == false) {
          price.focus();
        }
        applyError = true;
      } else if (price.value && !Number(price.value)) {
        price.classList.add("border-danger");
        price_msg.innerText = "Please enter valid selling price!";
        if (applyError == false) {
          price.focus();
        }
        applyError = true;
      } else {
        price.classList.remove("border-danger");
        price_msg.innerText = "";
        applyError = false;
      }
    }
    // Selling Price - End

    // RRP - Start
    if (callParam == "submit" || callParam == "rrp_price") {
      let rrp_price = document.getElementById("rrp_price");
      let rrp_price_msg = document.getElementById("rrp_price_msg");
      if (!rrp_price.value || rrp_price.value.trim() == "") {
        rrp_price.classList.add("border-danger");
        rrp_price_msg.innerText = "Please enter the course rrp price!";
        if (applyError == false) {
          rrp_price.focus();
        }
        applyError = true;
      } else if (rrp_price.value && !Number(rrp_price.value)) {
        rrp_price.classList.add("border-danger");
        rrp_price_msg.innerText = "Please enter the valid rrp price!";
        if (applyError == false) {
          rrp_price.focus();
        }
        applyError = true;
      } else {
        rrp_price.classList.remove("border-danger");
        rrp_price_msg.innerText = "";
        applyError = false;
      }
    }
    // RRP - End

    // Course Type - Start
    if (callParam == "submit" || callParam == "course_type") {
      let course_type = document.getElementById("course_type");
      let course_type_msg = document.getElementById("course_type_msg");
      if (!course_type.value || course_type.value.trim() == "") {
        course_type.classList.add("border-danger");
        course_type_msg.innerText = "Please select the course type!";
        if (applyError == false) {
          course_type.focus();
        }
        applyError = true;
      } else {
        course_type.classList.remove("border-danger");
        course_type_msg.innerText = "";
        applyError = false;
      }
    }
    // Course Type - End

    // Duration - Start
    if (callParam == "submit" || callParam == "duration") {
      let duration = document.getElementById("duration");
      let duration_msg = document.getElementById("duration_msg");
      if (!duration.value || duration.value.trim() == "") {
        duration.classList.add("border-danger");
        duration_msg.innerText = "Please select the course duration!";
        if (applyError == false) {
          duration.focus();
        }
        applyError = true;
      } else {
        duration.classList.remove("border-danger");
        duration_msg.innerText = "";
        applyError = false;
      }
    }
    // Duration - End

    // Course Level - Start
    if (callParam == "submit" || callParam == "course_level") {
      let course_level = document.getElementById("course_level");
      let course_level_msg = document.getElementById("course_level_msg");
      if (!course_level.value || course_level.value.trim() == "") {
        course_level.classList.add("border-danger");
        course_level_msg.innerText = "Please select the course level!";
        if (applyError == false) {
          course_level.focus();
        }
        applyError = true;
      } else {
        course_level.classList.remove("border-danger");
        course_level_msg.innerText = "";
        applyError = false;
      }
    }
    // Course Level - End

    // Certificate - Start
    if (callParam == "submit" || callParam == "certificate_line") {
      let certificate_line = document.getElementById("certificate_line");
      let certificate_line_msg = document.getElementById(
        "certificate_line_msg"
      );
      if (!certificate_line.value || certificate_line.value.trim() == "") {
        certificate_line.classList.add("border-danger");
        certificate_line_msg.innerText = "Please select the certificate level!";
        if (applyError == false) {
          certificate_line.focus();
        }
        applyError = true;
      } else {
        certificate_line.classList.remove("border-danger");
        certificate_line_msg.innerText = "";
        applyError = false;
      }
    }
    // Certificate - End

    // Course Code - Start
    if (callParam == "submit" || callParam == "course_code") {
      let course_code = document.getElementById("course_code");
      let course_code_msg = document.getElementById("course_code_msg");
      if (!course_code.value || course_code.value.trim() == "") {
        course_code.classList.add("border-danger");
        course_code_msg.innerText = "Please enter the course code!";
        if (applyError == false) {
          course_code.focus();
        }
        applyError = true;
      } else {
        course_code.classList.remove("border-danger");
        course_code_msg.innerText = "";
        applyError = false;
      }
    }
    // Course Code - End

    // Course Introduction - Start
    if (callParam == "submit" || callParam == "course_introduction") {
      let course_introduction = document.getElementById("course_introduction");
      let course_introduction_msg = document.getElementById(
        "course_introduction_msg"
      );
      if (
        !course_introduction.value ||
        course_introduction.value.trim() == ""
      ) {
        course_introduction.classList.add("border-danger");
        course_introduction_msg.innerText =
          "Please enter the course introduction!";
        if (applyError == false) {
          course_introduction.focus();
        }
        applyError = true;
      } else {
        course_introduction.classList.remove("border-danger");
        course_introduction_msg.innerText = "";
        applyError = false;
      }
    }
    // Course Introduction - End

    // Upload Course Image - Start
    if (
      callParam == "submit_course_image" ||
      callParam == "upload_course_image"
    ) {
      let upload_course_image = document.getElementById("upload_course_image");
      let upload_course_image_msg = document.getElementById(
        "upload_course_image_msg"
      );
      if (
        !upload_course_image.value ||
        upload_course_image.value.trim() == ""
      ) {
        upload_course_image.classList.add("border-danger");
        upload_course_image_msg.innerText = "Please upload the course image!";
        if (applyError == false) {
          upload_course_image.focus();
        }
        applyError = true;
      } else {
        upload_course_image.classList.remove("border-danger");
        upload_course_image_msg.innerText = "";
        applyError = false;
      }
    }
    // Upload Course Image - End

    // Upload Course Video - Start
    if (
      callParam == "submit_course_video" ||
      callParam == "upload_course_video"
    ) {
      let upload_course_video = document.getElementById("upload_course_video");
      let upload_course_video_msg = document.getElementById(
        "upload_course_video_msg"
      );
      if (
        !upload_course_video.value ||
        upload_course_video.value.trim() == ""
      ) {
        upload_course_video.classList.add("border-danger");
        upload_course_video_msg.innerText = "Please upload the course video!";
        if (applyError == false) {
          upload_course_video.focus();
        }
        applyError = true;
      } else {
        upload_course_video.classList.remove("border-danger");
        upload_course_video_msg.innerText = "";
        applyError = false;
      }
    }
    // Upload Course Video - End

    // Upload Course PPT - Start
    if (callParam == "submit_course_ppt" || callParam == "upload_course_ppt") {
      let upload_course_ppt = document.getElementById("upload_course_ppt");
      let upload_course_ppt_msg = document.getElementById(
        "upload_course_ppt_msg"
      );
      if (!upload_course_ppt.value || upload_course_ppt.value.trim() == "") {
        upload_course_ppt.classList.add("border-danger");
        upload_course_ppt_msg.innerText = "Please upload the course PPT!";
        if (applyError == false) {
          upload_course_ppt.focus();
        }
        applyError = true;
      } else {
        upload_course_ppt.classList.remove("border-danger");
        upload_course_ppt_msg.innerText = "";
        applyError = false;
      }
    }
    // Upload Course PPT - End

    // Upload Course Resource - Start
    if (
      callParam == "submit_course_resource" ||
      callParam == "upload_course_resource"
    ) {
      let upload_course_resource = document.getElementById(
        "upload_course_resource"
      );
      let upload_course_resource_msg = document.getElementById(
        "upload_course_resource_msg"
      );
      if (
        !upload_course_resource.value ||
        upload_course_resource.value.trim() == ""
      ) {
        upload_course_resource.classList.add("border-danger");
        upload_course_resource_msg.innerText =
          "Please upload the course resource!";
        if (applyError == false) {
          upload_course_resource.focus();
        }
        applyError = true;
      } else {
        upload_course_resource.classList.remove("border-danger");
        upload_course_resource_msg.innerText = "";
        applyError = false;
      }
    }
    // Upload Course Resource - End

    // Assessment - Start
    if (callParam == "submit" || callParam == "assessment") {
      let assessment = document.getElementById("assessment");
      let assessment_msg = document.getElementById("assessment_msg");
      if (!assessment.value || assessment.value.trim() == "") {
        assessment.classList.add("border-danger");
        assessment_msg.innerText = "Please enter the assessment details!";
        if (applyError == false) {
          assessment.focus();
        }
        applyError = true;
      } else {
        assessment.classList.remove("border-danger");
        assessment_msg.innerText = "";
        applyError = false;
      }
    }
    // Assessment - End

    // Certificate Description - Start
    if (callParam == "submit" || callParam == "certificate") {
      let certificate = document.getElementById("certificate");
      let certificate_msg = document.getElementById("certificate_msg");
      if (!certificate.value || certificate.value.trim() == "") {
        certificate.classList.add("border-danger");
        certificate_msg.innerText = "Please enter the certificate details!";
        if (applyError == false) {
          certificate.focus();
        }
        applyError = true;
      } else {
        certificate.classList.remove("border-danger");
        certificate_msg.innerText = "";
        applyError = false;
      }
    }
    // Certificate Description - End

    if (
      (callParam == "submit" ||
        callParam == "submit_course_resource" ||
        callParam == "submit_course_ppt" ||
        callParam == "submit_course_video" ||
        callParam == "submit_course_image") &&
      applyError == false
    ) {
      return applyError;
    } else {
      return true;
    }
  };
  // Validate Form Fields - End

  function handleThumbnailSubmitHandler() {
    if (validateFormFields("submit_course_image") == false) {
      let form = new FormData();
      form.append("course_id", state.id);
      form.append("thumbnail", thumbnail);
      setLoadingThumb(true);
      makeRequest("POST", "/course/update-course-thumbnail", form)
        .then((res) => {
          setThumbnail(null);
          setLoadingThumb(false);
          navigate("/view-course");
          swal("Done!", "Course Updated", "success");
          console.log(res);
        })
        .catch((err) => {
          setThumbnail(false);
          console.log(err);
        });
    }
  }

  function handleCourseInfoSubmit() {
    if (validateFormFields("submit") == false) {
      console.log(aims.split("#").filter((item) => item.replace(/\n/g, "")));
      let form = new FormData();
      let updatedCourse = {
        ...course,
        aims: JSON.stringify(aims.split("#")),
        who_should_attend: JSON.stringify(who_should_attend.split("#")),
        objectives_point: JSON.stringify(objectives_point.split("#")),
        what_you_will_learn_point: JSON.stringify(
          what_you_will_learn.split("#")
        ),
      };

      // console.log(updatedCourse);

      form.append("RRP", parseFloat(updatedCourse.RRP).toFixed(2));
      form.append("course_id", state.id);
      form.append("name", updatedCourse.name);
      form.append("description", updatedCourse.description);
      form.append("category", updatedCourse.category);
      form.append("thumbnail", updatedCourse.thumbnail);
      form.append("assessment", updatedCourse.assessment);
      form.append("certificate", updatedCourse.certificate);
      form.append("objective_define", updatedCourse.objective_define);
      form.append("What_you_will_learn", updatedCourse.What_you_will_learn);
      form.append("aims", updatedCourse.aims);
      form.append("who_should_attend", updatedCourse.who_should_attend);
      form.append("objectives_point", updatedCourse.objectives_point);
      // form.append(
      //   "what_you_will_learn_point",
      //   updatedCourse.what_you_will_learn_point
      // );
      form.append(
        "what_you_will_learn_point",
        what_you_will_learn_point ? JSON.stringify(what_you_will_learn_point.split('#')) : ""
      );
      form.append("price", parseFloat(updatedCourse.price).toFixed(2));
      form.append("course_type", updatedCourse.course_type);
      form.append("duration", updatedCourse.duration);
      form.append("course_level", updatedCourse.course_level);
      form.append("course_code", updatedCourse.course_code);
      form.append("certificate_line", updatedCourse.certificate_line);

      setLoading(true);
      makeRequest("POST", "/course/update-course-data", form)
        .then((res) => {
          navigate("/view-course");
          setLoading(false);
          swal("Done!", "Course Updated", "success");
          console.log(res);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }
  function handleVideoSubmitHandler() {
    if (validateFormFields("submit_course_video") == false) {
      let form = new FormData();
      form.append("course_id", state.id);
      form.append("video", video);
      setLoadingVideo(true);
      makeRequest("POST", "/course/update-course-video", form)
        .then((res) => {
          setLoadingVideo(false);
          navigate("/view-course");
          swal("Done!", "Cousre Updated", "success");
          console.log(res);
        })
        .catch((err) => {
          setLoadingVideo(false);
          console.log(err);
        });
    }
  }
  function handlePptSubmitHandler() {
    if (validateFormFields("submit_course_ppt") == false) {
      let form = new FormData();
      form.append("course_id", state.id);
      for (let i = 0; i < ppt?.length; i++) {
        form.append("image", ppt[i]);
      }
      setLoadingPpt(true);
      makeRequest("POST", "/course/update-course-ppt", form)
        .then((res) => {
          console.log(res);
          setLoadingPpt(false);
          navigate("/view-course");
          swal("Done!", "Cousre Updated", "success");
        })
        .catch((err) => {
          setLoadingPpt(false);
          console.log(err);
        });
    }
  }
  function handleResourceSubmitHandler() {
    if (validateFormFields("submit_course_resource") == false) {
      let form = new FormData();
      form.append("course_id", state.id);
      for (let i = 0; i < resource?.length; i++) {
        form.append("resource", resource[i]);
      }

      setLoadingRes(true);
      makeRequest("POST", "/course/update-course-resource", form)
        .then((res) => {
          console.log(res);
          setLoadingRes(false);
          navigate("/view-course");
          swal("Done!", "Course Updated", "success");
        })
        .catch((err) => {
          setLoadingRes(false);
          console.log(err);
        });
    }
  }

  function updateCourseData() {
    let form = new FormData();

    console.log(course);
    //
  }

  useEffect(() => {
    console.clear();
    makeRequest("GET", `/course/get-single-course/${state.id}`)
      .then((res) => {
        console.log(typeof res.data.response[0].aims);
        let course = res.data.response[0];
        console.log(course.aims);

        setAims(
          course.aims
            .map((item) => {
              if (item) {
                return "#" + item;
              }
            })
            .join("")
        );
        setWhoShouldSttend(
          course.who_should_attend
            .map((item) => {
              if (item) {
                return "#" + item;
              }
            })
            .join("")
        );
        setObjectivesPoint(
          course.objectives_point
            .map((item) => {
              if (item) {
                return "#" + item;
              }
            })
            .join("")
        );
        setWhatYouWillLearnPoint(
          course.what_you_will_learn_point
            .map((item) => {
              if (item) {
                return "#" + item;
              }
            })
            .join("")
        );
        setWhatYouWillLearn(
          course.what_you_will_learn_point
            .map((item) => {
              if (item) {
                return "#" + item;
              }
            })
            .join("")
        );

        setCourse({
          ...course,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleChange(e) {
    if (e.target.id) {
      validateFormFields(e.target.id);
    }
    setCourse((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }

  return (
    <div className="row">
      <div className="col-xl-11">
        <div
          className="card"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            background: "#f5f5f7",
          }}
        >
          <div
            style={{
              display: "",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <form type="button" onSubmit={(e) => e.preventDefault()}>
              <p className="text-center p-2 mb-0 mt-3">
                <h2 className="mb-0">Update Course Details</h2>
              </p>
              <hr />
              <div className="row p-5">
                <div className="col-sm-4 mb-3" style={{}}>
                  <label className="">
                    <b>
                      Course Name: <text className="text-danger">*</text>
                    </b>
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={course.name}
                    onChange={handleChange}
                    className="form-control input-default "
                    placeholder="Course Name"
                  />
                  <div
                    className="text-danger"
                    id="name_msg"
                    style={{ fontSize: "80%" }}
                  ></div>
                </div>

                <div className="col-sm-4 mb-3" style={{}}>
                  <label className="">
                    <b>
                      Course Category: <text className="text-danger">*</text>
                    </b>
                  </label>
                  <select
                    onChange={handleChange}
                    defaultValue={"option"}
                    name="category"
                    id="category"
                    className="form-control form-control"
                  >
                    <option value="">Select Course Category</option>
                    <option
                      value="Care Course"
                      selected={course.category == "Care Course" ? true : false}
                    >
                      Care Course
                    </option>
                    <option
                      value="Mandatory Care Course"
                      selected={
                        course.category == "Mandatory Care Course"
                          ? true
                          : false
                      }
                    >
                      Mandatory Care Course
                    </option>
                    <option
                      value="Specialised Care Course"
                      selected={
                        course.category == "Specialised Care Course"
                          ? true
                          : false
                      }
                    >
                      Specialized Care Course
                    </option>
                    <option
                      value="Recovery Care Course"
                      selected={
                        course.category == "Recovery Care Course" ? true : false
                      }
                    >
                      Recovery Care Course
                    </option>
                    <option
                      value="Child Care Course"
                      selected={
                        course.category == "Child Care Course" ? true : false
                      }
                    >
                      Child Care Course
                    </option>
                  </select>
                  <div
                    className="text-danger"
                    id="category_msg"
                    style={{ fontSize: "80%" }}
                  ></div>
                </div>

                <div className="col-sm-4 mb-3" style={{}}>
                  <label className="">
                    <b>
                      Selling Price: <text className="text-danger">*</text>
                    </b>
                  </label>
                  <div className="input-group">
                    <span
                      style={{ background: "#212A50", color: "white" }}
                      className="input-group-text"
                    >
                      £
                    </span>
                    <input
                      name="price"
                      id="price"
                      value={course.price}
                      onChange={(e) => {
                        setCourse((prev) => {
                          console.log(e.target.name);
                          return {
                            ...prev,
                            [e.target.name]: e.target.value,
                          };
                        });
                        validateFormFields(e.target.id);
                      }}
                      type="text"
                      className="form-control input-default "
                      placeholder="0.00"
                    />
                  </div>
                  <div
                    className="text-danger"
                    id="price_msg"
                    style={{ fontSize: "80%" }}
                  ></div>
                </div>

                <div className="col-sm-4 mb-3" style={{}}>
                  <label className="">
                    <b>
                      RRP:<text className="text-danger">*</text>
                    </b>
                  </label>
                  <div className="input-group">
                    <span
                      style={{ background: "#212A50", color: "white" }}
                      className="input-group-text"
                    >
                      £
                    </span>
                    <input
                      name="RRP"
                      id="rrp_price"
                      value={course.RRP}
                      onChange={(e) => {
                        setCourse((prev) => {
                          console.log(e.target.name);
                          return {
                            ...prev,
                            [e.target.name]: e.target.value,
                          };
                        });
                        validateFormFields(e.target.id);
                      }}
                      type="text"
                      className="form-control input-default "
                      placeholder="0.00"
                    />
                  </div>
                  <div
                    className="text-danger"
                    id="rrp_price_msg"
                    style={{ fontSize: "80%" }}
                  ></div>
                </div>

                <div className="col-sm-4 mb-3" style={{}}>
                  <label className="">
                    <b>
                      Course Type:<text className="text-danger">*</text>
                    </b>
                  </label>
                  <select
                    onChange={handleChange}
                    defaultValue={"option"}
                    name="course_type"
                    id="course_type"
                    className="form-control form-control"
                  >
                    <option value="">Select Course Type</option>
                    <option
                      value="online"
                      selected={course.course_type == "online" ? true : false}
                    >
                      Online
                    </option>
                    <option
                      value="offline"
                      selected={course.course_type == "offline" ? true : false}
                    >
                      Offline
                    </option>
                  </select>
                  <div
                    className="text-danger"
                    id="course_type_msg"
                    style={{ fontSize: "80%" }}
                  ></div>
                </div>

                <div className="col-sm-4 mb-3" style={{}}>
                  <label className="">
                    <b>
                      Duration:<text className="text-danger">*</text>
                    </b>
                  </label>
                  <select
                    onChange={handleChange}
                    defaultValue={"option"}
                    name="duration"
                    id="duration"
                    className="form-control form-control"
                  >
                    <option value="">Select Course Duration</option>
                    <option
                      value="variable"
                      selected={course.duration == "variable" ? true : false}
                    >
                      Variable
                    </option>
                  </select>
                  <div
                    className="text-danger"
                    id="duration_msg"
                    style={{ fontSize: "80%" }}
                  ></div>
                </div>

                <div className="col-sm-4 mb-3" style={{}}>
                  <label className="">
                    <b>
                      Course Level:<text className="text-danger">*</text>
                    </b>
                  </label>
                  <select
                    onChange={handleChange}
                    defaultValue={"option"}
                    name="course_level"
                    id="course_level"
                    className="form-control form-control"
                  >
                    <option value="">Select Course Level</option>
                    <option
                      value="Intermediate"
                      selected={
                        course.course_level == "Intermediate" ? true : false
                      }
                    >
                      Intermediate
                    </option>
                    <option
                      value="Advanced"
                      selected={
                        course.course_level == "Advanced" ? true : false
                      }
                    >
                      Advanced
                    </option>
                  </select>
                  <div
                    className="text-danger"
                    id="course_level_msg"
                    style={{ fontSize: "80%" }}
                  ></div>
                </div>

                <div className="col-sm-4 mb-3" style={{}}>
                  <label className="">
                    <b>
                      Certificate:<text className="text-danger">*</text>
                    </b>
                  </label>
                  <select
                    onChange={handleChange}
                    defaultValue={"option"}
                    value={course.certificate_line}
                    name="certificate_line"
                    id="certificate_line"
                    className="form-control form-control"
                  >
                    <option value="">Select Certificate</option>
                    <option
                      value="certificate of completion"
                      selected={
                        course.certificate_line == "certificate of completion"
                          ? true
                          : false
                      }
                    >
                      Certificate of completion
                    </option>
                  </select>
                  <div
                    className="text-danger"
                    id="certificate_line_msg"
                    style={{ fontSize: "80%" }}
                  ></div>
                </div>

                <div className="col-sm-4 mb-3" style={{}}>
                  <label className="">
                    <b>
                      Course Code:<text className="text-danger">*</text>
                    </b>
                  </label>
                  <input
                    type="text"
                    name="course_code"
                    id="course_code"
                    value={course.course_code}
                    onChange={handleChange}
                    className="form-control input-default "
                    placeholder="LFC01"
                  />
                  <div
                    className="text-danger"
                    id="course_code_msg"
                    style={{ fontSize: "80%" }}
                  ></div>
                </div>

                <div className="col-sm-12 mb-3">
                  <label className="">
                    <b>
                      Course Introduction:<text className="text-danger">*</text>
                    </b>
                  </label>
                  <textarea
                    value={course.description}
                    onChange={handleChange}
                    name="description"
                    className="form-control"
                    rows="4"
                    id="course_introduction"
                    placeholder="Content"
                  ></textarea>
                  <div
                    className="text-danger"
                    id="course_introduction_msg"
                    style={{ fontSize: "80%" }}
                  ></div>
                </div>

                <div className="col-sm-6 mb-3">
                  <label className="">
                    <b>Aims:</b>
                  </label>
                  <small
                    style={{ padding: ".1rem" }}
                    className="text-danger ml-3"
                  >
                    (use # to seperate points <span>[optional]</span>)
                  </small>
                  <textarea
                    name="aims"
                    value={aims}
                    onChange={(e) => {
                      setAims(e.target.value);
                    }}
                    className="form-control"
                    rows="4"
                    id="comment"
                    placeholder="Content"
                  ></textarea>
                </div>

                <div className="col-sm-6 mb-3">
                  <label className="">
                    <b>Who should attend?:</b>
                  </label>
                  <small
                    style={{ padding: ".1rem" }}
                    className="text-danger ml-3"
                  >
                    (use # to seperate points <span>[optional]</span>)
                  </small>

                  <textarea
                    value={who_should_attend}
                    name="who_should_attend"
                    onChange={(e) => {
                      setWhoShouldSttend(e.target.value);
                    }}
                    className="form-control"
                    rows="4"
                    id="comment"
                    placeholder="Content"
                  ></textarea>
                </div>

                {/* Fields Added from Add Course - Start */}
                <div className="col-sm-6 mb-3">
                  <label className="">
                    <b>Objectives(points):</b>
                  </label>
                  <small
                    style={{ padding: ".1rem" }}
                    className="text-danger ml-3"
                  >
                    (use # to seperate points{" "}
                    <span>[optional]</span>)
                  </small>
                  <textarea
                    name="objectives_point"
                    value={objectives_point}
                    onChange={(e) => {
                      setObjectivesPoint(e.target.value);
                    }}
                    className="form-control"
                    rows="4"
                    id="comment"
                    placeholder="Content"
                  ></textarea>                  
                </div>

                <div className="col-sm-6 mb-3">
                  <label className="">
                    <b>What you will learn?(points):</b>
                  </label>
                  <small
                    style={{ padding: ".1rem" }}
                    className="text-danger ml-3"
                  >
                    (use # to seperate points{" "}
                    <span>[optional]</span>)
                  </small>
                  <textarea
                    value={what_you_will_learn_point}
                    name="what_you_will_learn_point"
                    onChange={(e) => {
                      setWhatYouWillLearnPoint(e.target.value);
                    }}
                    className="form-control"
                    rows="4"
                    id="comment"
                    placeholder="Content"
                  ></textarea>
                </div>

                <div className="col-sm-6 mb-3">
                  <label className="">
                    <b>Objectives(define):</b>
                  </label>
                  <small
                    style={{ padding: ".1rem" }}
                    className="text-danger ml-3"
                  >
                    <span>[optional]</span>
                  </small>

                  <label htmlFor="formFile" className="form-label"></label>
                  <textarea
                    value={course.objective_define}
                    onChange={handleChange}
                    name="objective_define"
                    className="form-control"
                    rows="4"
                    id="comment"
                    placeholder="Content"
                  ></textarea>
                </div>

                <div className="col-sm-6 mb-3">
                  <label className="">
                    <b>What you will learn?(define):</b>
                  </label>
                  <small
                    style={{ padding: ".1rem" }}
                    className="text-danger ml-3"
                  >
                    <span>[optional]</span>
                  </small>

                  <textarea
                    value={course.What_you_will_learn}
                    onChange={handleChange}
                    name="What_you_will_learn"
                    className="form-control"
                    rows="4"
                    id="comment"
                    placeholder="Content"
                  ></textarea>
                </div>
                {/* Fields Added from Add Course - End */}

                <div className="col-sm-12 mb-3">
                  <label className="">
                    <b>
                      Assessment:<text className="text-danger">*</text>
                    </b>
                  </label>
                  <textarea
                    value={course.assessment}
                    onChange={handleChange}
                    name="assessment"
                    className="form-control"
                    rows="4"
                    id="assessment"
                    placeholder="Content"
                  ></textarea>
                  <div
                    className="text-danger"
                    id="assessment_msg"
                    style={{ fontSize: "80%" }}
                  ></div>
                </div>

                <div className="col-sm-12 mb-1">
                  <label className="">
                    <b>
                      Certificate:<text className="text-danger">*</text>
                    </b>
                  </label>
                  <textarea
                    value={course.certificate}
                    onChange={handleChange}
                    name="certificate"
                    className="form-control"
                    rows="4"
                    id="certificate"
                    placeholder="Content"
                  ></textarea>
                  <div
                    className="text-danger"
                    id="certificate_msg"
                    style={{ fontSize: "80%" }}
                  ></div>
                </div>
              </div>
              <div
                classNAme="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                {!loading ? (
                  <Button
                    class="btn btn-success"
                    type="button"
                    variant="primary"
                    onClick={handleCourseInfoSubmit}
                  >
                    Update
                  </Button>
                ) : (
                  <button class="btn btn-primary" type="button" disabled>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                )}
              </div>

              <div className="row p-5">
                <div className="col-sm-6 mb-3">
                  <label className="">
                    <b>
                      Upload Course Image:<text className="text-danger">*</text>
                    </b>
                  </label>
                  <br />
                  <small className="text-danger ml-3">
                    Width*Height-760*460, size{"<"}100kb, format-jpg, png, jpeg,
                    webp (preffered)
                  </small>
                  <br />
                  <input
                    className="form-control"
                    type="file"
                    id="upload_course_image"
                    onChange={(e) => setThumbnail(e.target.files[0])}
                  />
                  <div
                    className="text-danger"
                    id="upload_course_image_msg"
                    style={{ fontSize: "80%" }}
                  ></div>
                  <br />
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    {!thumbnail || !loadingThumb ? (
                      <Button
                        className="btn btn-primary btn-sm"
                        type="button"
                        variant="primary"
                        onClick={handleThumbnailSubmitHandler}
                      >
                        Upload
                      </Button>
                    ) : (
                      <button
                        class="btn btn-primary btn-sm"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    )}
                  </div>
                </div>

                <div className="col-sm-6 mb-3">
                  <label className="">
                    <b>
                      Upload Course Video:<text className="text-danger">*</text>
                    </b>
                  </label>
                  <br />
                  <small className="text-danger ml-3">
                    Format-mp4, mkv, webm
                  </small>
                  <br />
                  <input
                    className="form-control"
                    type="file"
                    onChange={(e) => {
                      setVideo(e.target.files[0]);
                    }}
                    id="upload_course_video"
                  />
                  <div
                    className="text-danger"
                    id="upload_course_video_msg"
                    style={{ fontSize: "80%" }}
                  ></div>
                  <br />
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    {!video || !loadingVideo ? (
                      <Button
                        className="btn btn-primary btn-sm"
                        type="button"
                        variant="primary"
                        onClick={handleVideoSubmitHandler}
                      >
                        Upload
                      </Button>
                    ) : (
                      <button
                        className="btn btn-primary btn-sm"
                        type="button"
                        disabled
                      >
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    )}
                  </div>
                </div>

                <div className="col-sm-6 mb-3">
                  <label className="">
                    <b>
                      Upload Course ppt:<text className="text-danger">*</text>
                    </b>
                  </label>
                  <br />
                  <small className="text-danger ml-3">
                    Size{"<"}100kb, format-jpg, png, jpeg, webp*(preffered)
                  </small>
                  <br />
                  <input
                    className="form-control"
                    multiple={true}
                    onChange={(e) => setPpt(e.target.files)}
                    type="file"
                    id="upload_course_ppt"
                  />
                  <div
                    className="text-danger"
                    id="upload_course_ppt_msg"
                    style={{ fontSize: "80%" }}
                  ></div>
                  <br />
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    {!ppt || !loadingPpt ? (
                      <Button
                        className="btn btn-primary btn-sm"
                        type="button"
                        variant="primary"
                        onClick={handlePptSubmitHandler}
                      >
                        Upload
                      </Button>
                    ) : (
                      <button
                        class="btn btn-primary btn-sm"
                        type="button"
                        disabled
                      >
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    )}
                  </div>
                </div>

                <div className="col-sm-6 mb-3">
                  <label className="">
                    <b>
                      Upload Course Resource:
                      <text className="text-danger">*</text>
                    </b>
                  </label>
                  <br />
                  <small className="text-danger ml-3">
                    Format-pdf, mp4, mkv, webm, txt, docx, jpg, jpeg, webp, png
                  </small>
                  <br />
                  <input
                    onChange={(e) => setResource(e.target.files)}
                    className="form-control"
                    type="file"
                    id="upload_course_resource"
                    multiple
                  />
                  <div
                    className="text-danger"
                    id="upload_course_resource_msg"
                    style={{ fontSize: "80%" }}
                  ></div>
                  <br />
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    {!resource || !loadingRes ? (
                      <Button
                        className="btn btn-primary btn-sm"
                        type="button"
                        variant="primary"
                        onClick={handleResourceSubmitHandler}
                      >
                        Upload
                      </Button>
                    ) : (
                      <button
                        className="btn btn-primary btn-sm"
                        type="button"
                        disabled
                      >
                        <span
                          class="spinner-border spinner-border-sm "
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    )}
                  </div>
                </div>
              </div>

              {/* <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="m-2 p-2 ">
                  <Button
                    className=""
                    variant="primary"
                    type="button"
                    // onClick={submit}
                  >
                    Submit
                  </Button>
                </div>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCourse;
