import React from "react";
import { Link } from "react-router-dom";
import { Nav, Tab, Dropdown } from "react-bootstrap";
import { IMAGES, SVGICON } from "./Dashboard/Content";
import { FaEye } from "react-icons/fa";
import { Button, ButtonGroup } from "react-bootstrap";
import { FaTrashRestore } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { FaDeleteLeft } from "react-icons/fa6";
import Tabs from "react-bootstrap/Tabs";
import { ImCross } from "react-icons/im";
import { FaDownload } from "react-icons/fa";
import { BiSolidEdit } from "react-icons/bi";
import { RiChatDeleteFill } from "react-icons/ri";
import "../scss/care.css";

import PageTitle from "../layouts/PageTitle";
import { Row, Col, Card, Badge, ProgressBar } from "react-bootstrap";
import fetchData from "../../axios";
import { useEffect } from "react";
import { useState } from "react";
import { date } from "yup";

const svg1 = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);


const SalesTab = () => {
  const [reports, setReports] = useState([]);
  const [groupByDay, setGroupByDay] = useState([]);
  const [filteredGroupByDay, setFilteredGroupByDay] = useState([]);
  const [daily, setDaily] = useState([]);
  const [years, setYears] = useState([]);
  const [groupByYear, setGroupByYear] = useState([]);
  const [filteredGroupByYear, setFilteredGroupByYear] = useState([]);
  const [type, setType] = useState("day");
  var monthNames = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    12: "November",
    12: "December",
  };
  const makeRequest = fetchData();
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  function removeDup(arr) {
    return arr.filter((value, index, self) => self.indexOf(value) === index);
  }
  useEffect(() => {
    makeRequest("GET", "/course/get-all-purchased-course-group-by")
      .then((res) => {
        setDaily(res.data.response.dailyReport.reverse());
        setFilteredGroupByDay(res.data.response.groupByDay.reverse());
        setGroupByDay(res.data.response.groupByDay);
        setFilteredGroupByYear(res.data.response.groupByYear.reverse());
        let year = res.data.response.groupByYear.map(item => {
          return item.year;
        })
        setYears(removeDup(year));
        setGroupByYear(res.data.response.groupByYear);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  useEffect(() => {
    setFilteredGroupByDay(() => {
      if (month != "" && year != "") {
        return groupByDay
          .filter((data) => {
            if (data.month == month && data.year == year) {
              return data;
            }
          });
      } else if (month != "") {
        return groupByDay.filter((data) => data.month == month);
      } else if (year != "") {
        return groupByDay.filter((data) => data.year == year);
      } else {
        return groupByDay;
      }
    });
  }, [month, year]);
  return (
    <div className="card">
      <Col xl={12}>
        <Tabs
          defaultActiveKey="daily"
          id="fill-tab-example"
          className="mb-3 mt-2"
          fill
        >
          <Tab eventKey="daily" title="Daily">
            <Col lg={12}>
              <Card>
                <Card.Header>
                  <Card.Title></Card.Title>
                </Card.Header>

                <Card.Body>
                  <Table responsive>
                    <thead>
                      <tr style={{ background: "#212a50", color: "#fff" }}>
                        <th style={{ color: "#fff" }} className="width80">
                          <strong>SL</strong>
                        </th>
                        <th style={{ textAlign: "center", color: "#fff" }}>
                          <strong>User</strong>
                        </th>
                        <th style={{ textAlign: "center", color: "#fff" }}>
                          <strong>Course Count</strong>
                        </th>
                        <th style={{ textAlign: "center", color: "#fff" }}>
                          <strong>Time</strong>
                        </th>
                        <th style={{ textAlign: "center", color: "#fff" }}>
                          <strong>Date</strong>
                        </th>
                        <th style={{ textAlign: "center", color: "#fff" }}>
                          <strong>Amount</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {daily &&
                        daily.map((item, i) => {
                          return (
                            <tr>
                              <td>
                                <strong>{++i}</strong>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item.first_name + " " + item.last_name}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item.fake_course_count <= 0 ? 1 : item.fake_course_count}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item.time}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item.date}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                £ {parseFloat(item.amount).toFixed(2)}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Tab>

          <Tab eventKey="qualification" title="Monthly">
            <Col lg={12}>
              <Card>
                {/* <Card.Header>
                  <Card.Title></Card.Title>
               
              
                </Card.Header> */}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    marginRight: "2.4rem",
                  }}
                  className=""
                >
                  <div className=" mb-md-0 mb-3 form-group ">
                    <select
                      style={{
                        backgroundColor: "#5a9676",
                        color: "white",
                        textAlign: "left",
                      }}
                      onChange={(e) => setMonth(e.target.value)}
                      className="form-control mr-2"
                      aria-label="Default select example"
                    >
                      <option value="">Select Month </option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">Sepetember</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                  </div>
                  <div className="">
                    <select
                      style={{
                        backgroundColor: "#5a9676",
                        color: "white",
                        textAlign: "left",
                        marginLeft: ".2rem",
                      }}
                      onChange={(e) => setYear(e.target.value)}
                      className="form-control"
                      aria-label="Default select example"
                    >
                      <option value="">Select Year</option>
                      {years &&
                        years.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <Card.Body>
                  <Table responsive>
                    <thead>
                      <tr style={{ color: "#fff", background: "#212a50" }}>
                        <th className="width80" style={{ color: "#fff" }}>
                          <strong>SL No</strong>
                        </th>
                        <th style={{ textAlign: "center", color: "#fff" }}>
                          <strong>Course Count</strong>
                        </th>
                        <th style={{ textAlign: "center", color: "#fff" }}>
                          <strong>Date</strong>
                        </th>
                        <th style={{ textAlign: "center", color: "#fff" }}>
                          <strong>Amount</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredGroupByDay &&
                        filteredGroupByDay.map((item, i) => {
                          return (
                            <tr>
                              <td>
                                <strong>{++i}</strong>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item.total_course_count <= 0 ? 1 : item.total_course_count}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item.day + "/" + item.month + "/" + item.year}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                £ {parseFloat(item.total_amount).toFixed(2)}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Tab>

          <Tab eventKey="Work" title="Yearly">
            <Col lg={12}>
              <Card>
                <Card.Header>
                  <Card.Title></Card.Title>

                  <div>
                    {" "}
                    <div className="form-group  mb-md-0 mb-3">
                      <select
                        style={{ backgroundColor: "#5a9676", color: "white" }}
                        onChange={(e) =>
                          setFilteredGroupByYear(
                            e.target.value
                              ? groupByYear.filter(
                                  (data) => data.year == e.target.value
                                )
                              : groupByYear
                          )
                        }
                        className="form-control"
                        aria-label="Default select example"
                      >
                        <option value="">Select Year</option>
                        {years &&
                          years.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Table responsive>
                    <thead>
                      <tr style={{ background: "#212a50" }}>
                        <th className="width80">
                          <strong>Sl No</strong>
                        </th>
                        <th style={{ textAlign: "center" }}>
                          <strong>Course Count</strong>
                        </th>
                        <th style={{ textAlign: "center" }}>
                          <strong>Month</strong>
                        </th>
                        <th style={{ textAlign: "center" }}>
                          <strong>Year</strong>
                        </th>
                        <th style={{ textAlign: "center" }}>
                          <strong>Amount</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredGroupByYear &&
                        filteredGroupByYear.map((item, i) => {
                          return (
                            <tr>
                              <td>
                                <strong>{++i}</strong>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item.total_course_count <= 0 ? 1 : item.total_course_count}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {monthNames[item.month]}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item.year}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                £ {parseFloat(item.total_amount).toFixed(2)}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Tab>
        </Tabs>
      </Col>
    </div>
  );
};

export default SalesTab;
